import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import { CodeBlock } from '../../../components/CodeBlock';
import { Layout } from '../../../layouts/Layout';
import { SEO } from '../../../components/SEO';
import { styles as S } from '@ulule/owl-kit-components/dist/next.esm';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Identity > Typography > Copy" mdxType="SEO" />
    <h1>{`Copy`}</h1>
    <h2>{`XS: Regular 11px ; line-height 16px`}</h2>
    <CodeBlock preview scope={{
      S
    }} title="S.copy.XS" mdxType="CodeBlock">{`
  <S.copy.XS>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut convallis elit convallis, bibendum mauris et, lacinia mi. Pellentesque quis arcu dignissim nibh tempor placerat et et lorem.
  </S.copy.XS>
`}</CodeBlock>
    <h2>{`S: Regular 14px ; line-height 22px`}</h2>
    <CodeBlock preview scope={{
      S
    }} title="S.copy.S" mdxType="CodeBlock">{`
  <S.copy.S>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut convallis elit convallis, bibendum mauris et, lacinia mi. Pellentesque quis arcu dignissim nibh tempor placerat et et lorem.
  </S.copy.S>
`}</CodeBlock>
    <h2>{`M: Light 16px ; line-height 24px`}</h2>
    <CodeBlock preview scope={{
      S
    }} title="S.copy.M" mdxType="CodeBlock">{`
  <S.copy.M>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut convallis elit convallis, bibendum mauris et, lacinia mi. Pellentesque quis arcu dignissim nibh tempor placerat et et lorem.
  </S.copy.M>
`}</CodeBlock>
    <h2>{`L: Light 18px ; line-height 26px`}</h2>
    <CodeBlock preview scope={{
      S
    }} title="S.copy.L" mdxType="CodeBlock">{`
  <S.copy.L>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut convallis elit convallis, bibendum mauris et, lacinia mi. Pellentesque quis arcu dignissim nibh tempor placerat et et lorem.
  </S.copy.L>
`}</CodeBlock>
    <h2>{`Serif: Regular 18px ; line-height 26px`}</h2>
    <CodeBlock preview scope={{
      S
    }} title="S.copy.Serif" mdxType="CodeBlock">{`
  <S.copy.Serif>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut convallis elit convallis, bibendum mauris et, lacinia mi. Pellentesque quis arcu dignissim nibh tempor placerat et et lorem.
  </S.copy.Serif>
`}</CodeBlock>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      